@import '../../../scss/theme-bootstrap';

.my-account-consultations-v1 {
  .row {
    width: 100%;
    overflow: hidden;
  }
  .row .item {
    float: #{$ldirection};
    width: 50%;
  }
  .row .item:first-child {
    border-#{$rdirection}: 1px solid #dbdbdb;
  }
  .row .item .inner {
    overflow: hidden;
    padding: 0 5% 0 0;
    p {
      margin: 0;
    }
  }
  .row .item-last .inner {
    padding: 0 0 0 5%;
  }
  .skin-quiz .image {
    float: #{$ldirection};
    @include swap_direction(margin, 0 0 15px 0);
    width: 60%;
  }
  .skin-quiz .image img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }
  .skin-quiz .meta {
    float: #{$rdirection};
    width: 38%;
  }
  .skin-quiz .skin-type-results {
    @include swap_direction(margin, 0 0 10px 0);
  }
  .foundation-finder {
    padding: 0;
  }
  .foundation-finder .meta {
    float: #{$ldirection};
    width: 40%;
  }
  .meta {
    font-size: 13px;
  }
  .meta h4 {
    color: $color-text-black;
    font-size: 22px;
    line-height: 23px;
    @include swap_direction(margin, 0 0 10px 0);
  }
  .meta .button {
    margin-bottom: 8px;
  }
  .see_all {
    padding-top: 12px;
  }
  .foundation-finder-results {
    overflow: hidden;
    .product-image {
      display: inline-block;
      float: none;
      vertical-align: top;
      width: 49%;
      img {
        display: block;
        margin: 0 auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .product-info {
      display: inline-block;
      float: none;
      @include swap_direction(padding, 65px 0 0 0);
      width: 48%;
      .title,
      .title a {
        color: $color-text-black;
        &:hover {
          color: $color-cl-green;
        }
      }
      .add-to-bag {
        @include swap_direction(margin, 10px 0 0 0);
        .btn-add-to-bag {
          height: auto;
        }
      }
    }
  }
  .my-account-touts {
    overflow: hidden;
  }
  .my-account-touts .my-account-tout {
    float: #{$ldirection};
    margin: 35px 0;
    min-height: 120px;
    @include swap_direction(padding, 0 20px 0 0);
    width: 50%;
  }
  .my-account-touts .my-account-tout.tout-last {
    border-#{$ldirection}: 1px solid #dadada;
    @include swap_direction(padding, 0 0 0 20px);
  }
}
